import React from 'react';
const EmptyFrame = () => {
  return <svg width="767" height="310" viewBox="0 0 767 310" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="EmptyFrame" data-sentry-source-file="empty-frame.tsx">
      <rect width="56" height="1139" transform="matrix(-0.637022 -0.770846 -0.770846 0.637022 1059.55 62.6914)" fill="url(#paint0_linear_15131_57313)" data-sentry-element="rect" data-sentry-source-file="empty-frame.tsx" />
      <rect width="55" height="1139" transform="matrix(-0.637022 -0.770846 -0.770846 0.637022 1010.5 3.33594)" fill="url(#paint1_linear_15131_57313)" data-sentry-element="rect" data-sentry-source-file="empty-frame.tsx" />
      <rect width="56" height="1139" transform="matrix(-0.637022 -0.770846 -0.770846 0.637022 962.086 -55.2461)" fill="url(#paint2_linear_15131_57313)" fillOpacity="0.84" data-sentry-element="rect" data-sentry-source-file="empty-frame.tsx" />
      <rect width="55" height="1139" transform="matrix(-0.637022 -0.770846 -0.770846 0.637022 913.031 -114.602)" fill="url(#paint3_linear_15131_57313)" fillOpacity="0.1" data-sentry-element="rect" data-sentry-source-file="empty-frame.tsx" />
      <defs data-sentry-element="defs" data-sentry-source-file="empty-frame.tsx">
        <linearGradient id="paint0_linear_15131_57313" x1="28" y1="0" x2="28" y2="1139" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="empty-frame.tsx">
          <stop stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="empty-frame.tsx" />
          <stop offset="1" stopColor="white" data-sentry-element="stop" data-sentry-source-file="empty-frame.tsx" />
        </linearGradient>
        <linearGradient id="paint1_linear_15131_57313" x1="27.5" y1="0" x2="27.5" y2="1139" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="empty-frame.tsx">
          <stop stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="empty-frame.tsx" />
          <stop offset="1" stopColor="#1B815F" data-sentry-element="stop" data-sentry-source-file="empty-frame.tsx" />
        </linearGradient>
        <linearGradient id="paint2_linear_15131_57313" x1="28" y1="0" x2="28" y2="1139" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="empty-frame.tsx">
          <stop stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="empty-frame.tsx" />
          <stop offset="1" stopColor="white" data-sentry-element="stop" data-sentry-source-file="empty-frame.tsx" />
        </linearGradient>
        <linearGradient id="paint3_linear_15131_57313" x1="27.5" y1="0" x2="27.5" y2="1139" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="empty-frame.tsx">
          <stop stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="empty-frame.tsx" />
          <stop offset="1" stopColor="white" data-sentry-element="stop" data-sentry-source-file="empty-frame.tsx" />
        </linearGradient>
      </defs>
    </svg>;
};
export default EmptyFrame;