import ArrowUpIcon from "assets/icons/ArrowUpIcon";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useRouter } from "next/router";
import { useGetHotFilters } from "pages/main/services/queries";
import React, { useEffect } from "react";
import { RU, USD } from "utils/constants/locales";
import { rootPaths } from "utils/constants/rootPaths";
import { calcUsdValue } from "utils/helpers/calcUsdValue";
import { filterPriceSeparator } from "utils/helpers/filterPriceSeparator";
import { layoutLang } from "utils/language/layout";
import HotOffersLocation from "./HotOffersLocation";
import EmptyOfferIcon from "assets/icons/EmptyOfferIcon";
import { isEmptyArr } from "utils/helpers/isEmptyArr";
import { Spin } from "antd";
import CustomLink from "common/custom-link";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { DEFAULT_LOCATION } from "utils/constants/defaultLocation";
export const sub_titles = {
  price: {
    uz: "Eng arzon m² xonadon",
    ru: "Самые низкие цены за м²"
  },
  total_price: {
    uz: "Eng arzon xonadon",
    ru: "Самая дешевая квартира"
  },
  prepayment: {
    uz: "Eng past boshlang’ich to‘lov",
    ru: "Самый низкий первый взнос"
  },
  delay: {
    uz: "Eng uzoq muddatli to‘lov",
    ru: "Самый долгий период оплаты"
  },
  discount_percent: {
    uz: "Eng yuqori chegirmali",
    ru: "Самая высокая скидка"
  },
  monthly_price: {
    uz: "Eng past oylik to'lov",
    ru: "Самый низкий ежемес. платёж"
  }
};
const HotOffers = () => {
  const dispatch = useAppDispatch();
  const {
    locale,
    pathname,
    query
  } = useRouter();
  const currency = useAppSelector(state => state.localeReducer?.currency);
  const usdValue = useAppSelector(state => state.localeReducer?.usdValue);
  const hotOffersRegionValue = useAppSelector(state => state.favouritesReducer?.hotOffersRegionValue);
  const {
    setCurrentRegion
  } = favouritesReducerActions;
  const location = hotOffersRegionValue;
  useEffect(() => {
    dispatch(setCurrentRegion({
      type: "hot_location",
      value: {
        city: null,
        district: null
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const regionId = location?.city ? location?.city?.id : location?.district?.city?.id;
  const districtId = location?.district ? location?.district?.id : undefined;
  const {
    data,
    isLoading
  } = useGetHotFilters(regionId, districtId);
  const newData = data?.map(item => ({
    ...item,
    sub_title: locale === RU ? sub_titles[item?.sort_key as keyof typeof sub_titles]?.ru : sub_titles[item?.sort_key as keyof typeof sub_titles]?.uz
  }));
  const rootpath = pathname.includes("kvartiri") ? rootPaths.APARTMENTS : rootPaths.RESIDENTIAL_COMPLEXES;
  const path_Name = regionId && districtId ? `/${location?.city?.url || location?.district?.city?.url}${rootpath}` : regionId ? `/${location?.city?.url || location?.district?.city?.url}${rootpath}` : districtId ? `/${location?.district?.city?.url}${rootpath}` : `/${DEFAULT_LOCATION}${rootpath}`;
  const path_query = (sort_key: string, sort_value: string) => {
    if (regionId && districtId) {
      return {
        sort_value: sort_value,
        sort_key: sort_key,
        city_id: regionId,
        district_id: districtId
      };
    } else if (regionId) {
      return {
        sort_value: sort_value,
        sort_key: sort_key,
        city_id: regionId
      };
    } else if (districtId) {
      return {
        sort_value: sort_value,
        sort_key: sort_key,
        district_id: districtId
      };
    } else {
      return {
        sort_value: sort_value,
        sort_key: sort_key
      };
    }
  };
  return <div className={`bg-white rounded-xl ${pathname.includes('kvartiri') || pathname.includes("novostroyki") ? 'mt-[89px]' : 'mt-[50px]'} px-2 py-3 left-6 flex-col gap-4 sticky top-[100px] h-max min-w-[296px] max-w-[296px] desctop_hot_offers`} data-sentry-component="HotOffers" data-sentry-source-file="HotOffers.tsx">
      <div className="flex flex-col gap-1">
        <span className="text-[#344054] text-lg font-semibold leading-[22px]">
          {layoutLang[locale || 'ru']["qaynoq_takliflar"]}
        </span>
        <HotOffersLocation data-sentry-element="HotOffersLocation" data-sentry-source-file="HotOffers.tsx" />
      </div>
      <Spin spinning={isLoading} data-sentry-element="Spin" data-sentry-source-file="HotOffers.tsx">
        {!isEmptyArr(newData) ? <div className="flex flex-col gap-3">
            {newData?.map((item, index) => <div key={index} className="relative">
                <CustomLink key={index} className="static" href={{
            pathname: path_Name,
            query: query?.currency ? {
              ...path_query(item?.sort_key, item?.sort_value),
              currency: query?.currency
            } : path_query(item?.sort_key, item?.sort_value)
          } as never} onClick={() => {
            dispatch(setCurrentRegion({
              type: "location",
              value: location
            }));
          }}>
                  <span className="absolute w-full h-full top-0 left-0 z-[1] block"></span>
                </CustomLink>
                <div className={`flex flex-col gap-[1px] p-2 pl-3 border border-solid  rounded-xl cursor-pointer ${item?.sort_key === query?.sort_key ? 'bg-[#FFF9F3] border-[#DC6803]' : 'bg-[#F9FAFB] border-[#F2F4F7]'}`}>
                  <div className="flex items-center justify-between">
                    <span className="text-[#1D2939] text-[22px] leading-[27px] font-medium">
                      {item?.sort_key === "delay" || item?.sort_key === "discount_percent" ? item?.sort_key === "delay" ? `${item?.value} ${layoutLang[locale || 'ru']["oy"]}` : `${item?.value} %` : `${currency === USD ? `${Math.ceil(calcUsdValue(item?.value, usdValue, currency))?.toLocaleString(RU)} $` : `${filterPriceSeparator(item?.value, locale)} ${layoutLang[locale || 'ru']["sum"]}`}`}
                    </span>
                    {item?.percent && <div className="flex items-center gap-0.5">
                        {item?.percent < 0 ? <ArrowUpIcon /> : <div className="rotate-180">
                            <ArrowUpIcon stroke="#F04438" />
                          </div>}
                        <span className={`text-xs font-medium ${item?.percent > 0 ? "text-[#F04438]" : "text-[#12B76A]"}`}>{`${item?.percent?.toString()?.replace("-", "")} %`}</span>
                      </div>}
                  </div>
                  <span className="text-[#00A389] text-[13px] font-medium">
                    {item?.sub_title}
                  </span>
                </div>
              </div>)}
          </div> : <div className="flex flex-col items-center gap-3.5 mt-6">
            <EmptyOfferIcon />
            <span className="text-[#344054] text-base font-semibold">
              {layoutLang[locale || 'ru']["malumot topilmadi"]}
            </span>
          </div>}
      </Spin>
    </div>;
};
export default HotOffers;