import { RU } from "utils/constants/locales";
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/apis"

import  { stringify } from "query-string";

// top complexes
export const fetchTopComplexes = () => {
  const url = `/complex/top?size=12`

  return {
    fetch: async () => {
      const res = await $api.get(url)
      return res.data?.data ? res.data?.data : res.data
    },
    url,
  }
}

// apartment count
export const fetchApartmentCounts = async () => {
  const res = await $api.get(endpoints.APARTMENT_COUNT)
  return res.data.data
}

// top builders
export const fetchTopBuilders = async () => {
  const res = await $api.get(endpoints.TOP_BUILDERS)
  return res.data.data
}

// top magazines
export const fetchTopMagazines = async (
  lang?: string,
  // article_ids?: number[],
) => {
  const url = `article/top?lang=${lang || RU}&top=true`

  const res = await $api.get(url)
  return res.data.data
}

export const fetchHotFilters = async (
  city_id?: number,
  district_id?: number
) => {
  const url =
    city_id && district_id
      ? `${endpoints.HOT_FILTERS}?city_id=${city_id}&district_id=${district_id}`
      : city_id
      ? `${endpoints.HOT_FILTERS}?city_id=${city_id}`
      : `${endpoints.HOT_FILTERS}`

  const res = await $api.get(url)

  return res.data.data
}

export const fetchFilteredCount = async (filters: any) => {
  let url = `/complex/count`;

  if(filters){
    url += `?${stringify(filters, {arrayFormat: "comma"})}`
  }

  const res = await $api.get(url)
  return res.data.data
}

export async function fetchLatestAddedComplexes() {
  const res = await $api.get("/complex/last-created?size=6");
  return res.data?.data;
}


export const fetchReadyComplexes = async()=>{
  const res = await $api.get("/complex/completed")
  return res.data?.data
}

export const fetchMainReels = async()=>{
  const res = await $api.get("/reels/top")
  return res.data?.data
}
