export const queryKeys = {
  COUNTS: "counts",
  TOP_COMPLEXES: "top-complexes",
  TOP_BUILDERS: "top-builders",
  NEW_TOP_MAGAZINES: "new-top-magazines",
  APARTMENT_COUNTS: "apartment-counts",
  FAVOURITES: "favourites",
  MAIN_REGIONS: "MAIN_REGIONS",
  HOT_FILTERS: "HOT_FILTERS",
  UYSOT_COIN: "UYSOT_COIN",
  APARTMENTS_COUNT: "APARTMENTS_COUNT",
  LATEST_ADDED_COMPLEXES:"LATEST_ADDED_COMPLEXES",
  READY_COMPLEXES:"READY_COMPLEXES",
  REGION_DISTRICTS: "REGION_DISTRICTS",
  MAIN_REELS: "MAIN_REELS",
}
