import React from 'react';
import { useRouter } from 'next/router';
import ArrowBottom from 'assets/icons/ArrowBottom';
import { findLinksInText } from 'utils/helpers/findLinksInText';
import { layoutLang } from 'utils/language/layout';
import { useFaqViewByType } from 'pages/faq/services/queries';
import styles from './index.module.scss';
type Props = {
  type: string;
};
const CustomFAQ: React.FC<Props> = ({
  type
}) => {
  const {
    locale
  } = useRouter();
  const {
    data: faqs
  } = useFaqViewByType(type);
  if (!faqs || faqs?.length === 0) return null;
  return <div className={styles.category} data-sentry-component="CustomFAQ" data-sentry-source-file="index.tsx">
      <h2>{layoutLang[locale || 'ru']?.['Остались вопросы?']}</h2>
      <div className={styles.category_item}>
        {faqs.map(faq => <details key={faq.id} className={styles.faq_item}>
            <summary className={styles.faq_summary}>
              <span className='w-[5%] md:w-auto'>
                <ArrowBottom className={styles.arrow_icon} />
              </span>
              <span className="line-clamp-2 w-[95%] md:w-auto" title={faq.question?.[locale || 'ru']}>
                {faq.question?.[locale || 'ru']}
              </span>
            </summary>
            <div className={styles.faq_content} dangerouslySetInnerHTML={{
          __html: findLinksInText(faq.answer?.[locale || 'ru'])
        }}></div>
          </details>)}
      </div>
    </div>;
};
export default CustomFAQ;