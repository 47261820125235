import { cloneElement, useEffect, useRef } from "react";
// import { useBannerSendView } from "../../pages/app/services/queries";
import { useCreateUserInteractions } from "pages/detailed-magazine/service/mutations";
interface IProps {
  children: JSX.Element;
  uuid?: number;
}
const ViewAdv = ({
  children,
  uuid
}: IProps) => {
  const bodyRef = useRef<HTMLElement | null>(null);
  // useBannerSendView(uuid);
  const mutate = useCreateUserInteractions();
  const lastViewedUuid = useRef<number | null>(null); // Store the last UUID to track changes

  function buildThresholdList() {
    const thresholds = [];
    const numSteps = 20;
    for (let i = 1.0; i <= numSteps; i++) {
      const ratio = i / numSteps;
      thresholds.push(ratio);
    }
    thresholds.push(0);
    return thresholds;
  }
  useEffect(() => {
    const currentRef = bodyRef?.current;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry?.isIntersecting && uuid && lastViewedUuid.current !== uuid) {
        // Run mutation only when UUID changes and the content is visible
        mutate.mutate({
          interaction_type: "view",
          advertisement_id: uuid as never
        });
        lastViewedUuid.current = uuid; // Update the last seen UUID
      }
    }, {
      root: null,
      rootMargin: "0px",
      threshold: buildThresholdList()
    });
    if (currentRef) {
      observer.observe(currentRef);
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]); // Only trigger effect when uuid changes

  return cloneElement(children, {
    ref: bodyRef
  });
};
export default ViewAdv;