import { useQuery } from "@tanstack/react-query"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { NewResidentalComplexModel } from "../../../utils/models/residentialComplexModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { MagazineType } from "../../magazines/utils/models/magazineModel"
import { queryKeys } from "../utils/constants/queryKeys"
import {
  fetchApartmentCounts,
  fetchFilteredCount,
  fetchHotFilters,
  fetchLatestAddedComplexes,
  fetchMainReels,
  fetchReadyComplexes,
  fetchTopBuilders,
  fetchTopComplexes,
  fetchTopMagazines,
} from "./queryFunctions"
import { useRouter } from "next/router"
import { Pagination } from "utils/models/pagination"
import { HotFiltersModel } from "pages/magazines/utils/models/hotFiltersModel"
import { OneBuilderModel } from "pages/builders/utils/models/oneBuilderModel"
import { MainReelsModel } from "../utils/models/main-reels-model"

// get top residential complexes
export function useGetTopResidentialComplexes() {
  const { url, fetch } = fetchTopComplexes()

  return useQuery<Pagination<NewResidentalComplexModel>, ErrorRes>({
    queryKey: [queryKeys.TOP_COMPLEXES, url],
    queryFn: fetch,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
  })
}

// get apartment counts
export function useGetApartmentCounts() {
  return useQuery<{ rooms_count: number; apartments_count: number }[], ErrorRes>({
    queryKey: [queryKeys.APARTMENT_COUNTS],
    queryFn: fetchApartmentCounts,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity
  });
}

// get top builders
export function useGetTopBuilders() {
  return useQuery<OneBuilderModel[], ErrorRes>({
    queryKey: [queryKeys.TOP_BUILDERS],
    queryFn: fetchTopBuilders,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity
  });
}

// get top magazines
export function useGetTopMagazines() {
  const { locale } = useRouter();

  return useQuery<MagazineType[], ErrorRes>({
    queryKey: [queryKeys.NEW_TOP_MAGAZINES, locale],
    queryFn: () => {
      return fetchTopMagazines(locale);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity
  });
}

// get hot filters

export function useGetHotFilters(city_id?: number, district_id?: number) {
  const hotQueryKeys =
    city_id && district_id!
      ? [queryKeys.HOT_FILTERS, Number(city_id), Number(district_id)]
      : city_id
        ? [queryKeys.HOT_FILTERS, Number(city_id)]
        : [queryKeys.HOT_FILTERS];

  return useQuery<HotFiltersModel[], ErrorRes>({
    queryKey: hotQueryKeys,
    queryFn: () =>
      fetchHotFilters(city_id ? Number(city_id) : undefined, district_id ? Number(district_id) : undefined),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler
  });
}


export function useGetApartmentsCount(filters: any){
  return useQuery({
    queryKey: [queryKeys.APARTMENTS_COUNT, filters],
    queryFn: () => fetchFilteredCount(filters),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
    enabled: Object.keys(filters)?.length > 0
  })
}

export function useGetLatestAddedComplexes(){
  return useQuery({
    queryKey: [queryKeys.LATEST_ADDED_COMPLEXES],
    queryFn: () => fetchLatestAddedComplexes(),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

export function useGetReadyComplexes(){
  return useQuery({
    queryKey: [queryKeys.READY_COMPLEXES],
    queryFn: () => fetchReadyComplexes(),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

export function useGetMainReels(enabled?:boolean){
  return useQuery<MainReelsModel[], ErrorRes>({
    queryKey: [queryKeys.MAIN_REELS],
    queryFn: () => fetchMainReels(),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
    enabled
  })
}